import Separator from "@/shared/Separator";
import { ParentGrid, Card } from "./style";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
import Banner from "@/widgets/Banner/Templates/Banner";
import { BannerCajaGris } from "@/shared/ContentBanner";
import BannerLazy from "@/widgets/Banner/Templates/BannerLazy";
import { sizeBanner } from "@/shared/DFP/Home/mapping";
import { useContainerWidget } from "hooks";
import Skeleton from "./Skeleton";

const filterSizeBanner = (slot, sizeBanner) => {
  return sizeBanner[Object.keys(sizeBanner).find((item) => item == slot)];
};

const Container1Piso4 = (props) => {
  const { banners, link, linkImage, sectionClass = "", title } = props;

  const { indexNews, loading, titlesExtras, marfeelTitle } = useContainerWidget(
    { arrayLength: 5, containerName: "Container1Piso4", ...props }
  );

  const defaultTemplate = ["TwoxTwoVisual", "Half", "Half", "Half", "Half"];

  return (
    props.content?.length > 0 && (
      <>
        {title && (
          <Separator
            title={title}
            link={link}
            sectionClass={sectionClass}
            extras={titlesExtras}
            linkImage={linkImage}
          />
        )}
        {loading && !props.isPreview ? (
          <Skeleton />
        ) : (
          <GridStyleColor>
            <ParentGrid
              data-mrf-recirculation={`${marfeelTitle}`}
              className={`Container1Piso4 module-grid ${sectionClass}`}
            >
              {indexNews.map((item, index) => {
                return (
                  <>
                    {index === 1 && (
                      <Card
                        className={`div${index + 1}`}
                        key={`container${index + 1}`}
                      >
                        {banners && (
                          <BannerCajaGris
                            heightBanner="600px"
                            className="banner sticky"
                          >
                            {filterSizeBanner(banners, sizeBanner) ? (
                              <BannerLazy
                                isPreview={props.isPreview}
                                slotId={banners}
                                adsPath="home"
                                mapping={filterSizeBanner(banners, sizeBanner)}
                              />
                            ) : (
                              <Banner
                                isPreview={props.isPreview}
                                slotId={banners}
                              />
                            )}
                          </BannerCajaGris>
                        )}
                      </Card>
                    )}
                    <Card
                      className={`div${index >= 1 ? index + 2 : index + 1}`}
                      key={`container${index >= 1 ? index + 2 : index + 1}`}
                    >
                      {props.renderContainerWidgetCallback(
                        item + 1,
                        props.positionProperties?.[index]?.templateNews ??
                          defaultTemplate[index],
                        { containerName: "contenedor_1_piso_4" }
                      )}
                    </Card>
                  </>
                );
              })}
            </ParentGrid>
          </GridStyleColor>
        )}
      </>
    )
  );
};
export default Container1Piso4;
